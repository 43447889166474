import { Controller } from "@hotwired/stimulus"
import $ from "jquery";
import 'select2';

// Connects to data-controller="select2"
export default class extends Controller {
  connect() {
    this.initializeSelect2();
  }

  initializeSelect2() {
    if ($(this.element).attr('multiple')) {
      $(this.element).select2({
        placeholder: '',
        maximumSelectionLength: 3
      });
    } else {
      $(this.element).select2({
        placeholder: ''
      });
    }
  }
}
