import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="conditional-fields-production-system"
export default class extends Controller {
  static targets = ["livestockType"];

  connect() {
    this.toggleFields();
  }

  toggleFields() {
    const selectedType = this.livestockTypeTarget.value.toLowerCase();
    const allTypes = ["sheep", "beef", "dairy"];

    allTypes.forEach((type) => {
      const elements = document.querySelectorAll(`.${type}-field`);
      elements.forEach((el) => {
        if (type === selectedType || selectedType === "") {
          el.classList.remove("hide");
        } else {
          el.classList.add("hide");
        }
      });
    });
  }
}
